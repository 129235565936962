import React from "react";

function NavigationDots({ active }) {
  return (
    <div className="app__navigation">
      {["umbral", "bio", "creaciones", "Expertise","opiniones", "conectar"].map(
        (item, index) => (
          <a
            href={`#${item}`}
            key={item + index}
            className="app__navigation-dot"
            rel="noreferrer"
            style={active === item ? { backgroundColor: "#313BAC" } : {}}
          />
        ) 
      )}
    </div>
  );
}

export default NavigationDots;
