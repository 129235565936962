import React from "react";
import { VscGithubInverted, VscCode } from "react-icons/vsc"
import { LiaLinkedinIn } from "react-icons/lia"
const SocialMedia = () => {
  return (
    <div className="app__social" >
      <a href="https://www.linkedin.com/in/josue-go/"  target="_blank" title="ir a perfil de linkedin" rel="noreferrer">
        <LiaLinkedinIn />
      </a>
      <a href="https://github.com/GJosueO" target="_blank" title="ir a perfil de github" rel="noreferrer">
        <VscGithubInverted />
      </a>      
      <a href="https://codepen.io/Josue-GO" target="_blank" title="ir a perfil de codepen" rel="noreferrer" >
        <VscCode />
      </a>
    </div>
  );
};

export default SocialMedia;
