import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import "./Header.scss";
import { AppWrap } from "../../wrapper";
import { IoLogoJavascript } from "react-icons/io5"

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => (
  
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div className="badge-cmp app__flex">        
          <div style={{ marginLeft: 20 }}>
            <p className="p-text">¡Bienvenid@, estoy emocionado de que estes leyendo esto! 😊</p>

            <h1 className="head-text">Soy Josue</h1>
          </div>
        </div>

        <div className="tagstack-cmp app__flex">
          <p className="p-text p-stack">UI/UX Frontend Developer</p>
          <p className="p-text p-stack" style={{textAlign:'center'}}>WEB / MOBILE </p>
          <p className="p-text p-stack" style={{ textAlign: 'center' }}>Full Stack MERN 🗂️</p>
          <p className="p-text p-stack" style={{ textAlign: 'center' }}>Apasionado por el SEO y experto en JavaScript <IoLogoJavascript style={{color:"#ffd600", background:"", fontSize:20}}/></p>
          
        </div>
        <div className="tag-cmp app__flex">
          <span>👣</span>
            <p className="p-text">Aqui te presentaré mi camino en el Desarrollo Web & Mobile✨</p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img" 
    >
      <img src={images.profile} alt="profile_bg" />
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: "easeInOut" }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      />
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.csharp, images.javascript, images.dart].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>
    
  </div>
);

export default AppWrap(Header, "umbral");
