import React from 'react';
import { About, Footer, Header, Skills, Testimonial, Work} from './container';
import { Navbar } from './components';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
// import HomeS from './pages/Home';
// import AboutS from './pages/About';
const App = () => {
  return (
    <div className='app'>
      {/* <BrowserRouter>
        <Routes>
          <Route element={<HomeS />} path='HomeS'/>
          <Route element={<AboutS />} path='AboutS' />
        </Routes>
      </BrowserRouter> */}
        <Navbar />
        <Header />
        <About />
        <Work />
        <Skills />
        <Testimonial />
        <Footer />
    </div>
  )
}

export default App;
